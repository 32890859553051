import * as THREE from "three"

export default class ReactiveObject extends THREE.Group {
    constructor(w, h, depth = 1) {
        super()

        const colors = [
            0xff0000,
            0xff7f00,
            0xffff00,
            0x00ff00,
            0x0000ff,
            0x4b0082,
            0x8f00ff,
        ]
        let spectrumColor
        let n = 0

        for (let i = 0; i < 256; i++) {
            if (i % 37 === 0) {
                spectrumColor = colors[n]
                n++
            }
            const geometry = new THREE.BoxGeometry(w, h, depth)
            const material = new THREE.MeshBasicMaterial({
                color: spectrumColor,
            })
            const cube = new THREE.Mesh(geometry, material)
            cube.position.set(i * 2, 0, 0)
            this.add(cube)
        }

        this.position.set(-256, -100, 0)
    }

    render(frequence) {
        for (let i = 0; i < this.children.length; i++) {
            let off = frequence[i]
            this.children[i].position.y = off / 2
            this.children[i].scale.y = off + 0.0000001
        }
    }
}
