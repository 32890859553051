import Scene from "./js/Scene"
import AudioManager from "./js/AudioManager"
import { generateHTML, detectSelectedSound } from "./js/Utils"

generateHTML()
let audiomanager
let scene
let instance = true

const userInput = document.getElementById("user-input")

// audiomanager = new AudioManager()
// audiomanager.getSounds("pnl")
// const scene = new Scene(audiomanager)
// scene.start()

document.addEventListener("keypress", (e) => {
    if (instance) {
        audiomanager = new AudioManager()
        scene = new Scene(audiomanager)
        scene.start()
        instance = false
    }

    if (e.keyCode === 13 && userInput.value !== "" && audiomanager.menuOpen) {
        audiomanager.getSounds(userInput.value)
    }
})

document.querySelector(".select-view").addEventListener("click", (e) => {
    if (e.target.getAttribute("data-object")) {
        scene.changeObject(e.target.getAttribute("data-object"))
    }
})

document.querySelector(".music-container").addEventListener("click", (e) => {
    const dataAudio = detectSelectedSound(e)

    if (dataAudio !== undefined) {
        audiomanager.playSelectedSound(dataAudio)
    }
})
