import * as THREE from "three"
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
// import { GUI } from "three/examples/jsm/libs/dat.gui.module.js"
// import gsap from "gsap"

import ReactiveObject from "./ReactiveObject"
import Cube from "./Cube"
import Circle from "./Circle"

// const gui = new GUI()

export default class Scene {
    constructor(audiomanager) {
        this.w = window.innerWidth
        this.h = window.innerHeight
        this.camera = new THREE.PerspectiveCamera(
            75,
            this.w / this.h,
            0.1,
            5000
        )
        this.audiomanager = audiomanager
        this.scene = new THREE.Scene()
        this.renderer = new THREE.WebGLRenderer()
        this.renderer.setSize(this.w, this.h)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        document.body.appendChild(this.renderer.domElement)

        this.renderScene = new RenderPass(this.scene, this.camera)

        this.params = {
            bloomStrength: 0.6,
            bloomRadius: 0,
        }

        this.bloomPass = new UnrealBloomPass(
            new THREE.Vector2(this.w, this.h),
            this.params.bloomStrength,
            0,
            this.params.bloomRadius
        )

        // gui.add(this.params, "bloomStrength", 0.0, 3.0).onChange((value) => {
        //     this.bloomPass.strength = Number(value)

        //     console.log(this.bloomPass.strength)
        // })
        // gui.add(this.params, "bloomRadius", 0.0, 1.0)
        //     .step(0.01)
        //     .onChange((value) => {
        //         this.bloomPass.radius = Number(value)
        //     })

        this.composer = new EffectComposer(this.renderer)
        this.composer.addPass(this.renderScene)
        this.composer.addPass(this.bloomPass)

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement)

        // this.controls.minDistance = 10
        // this.controls.maxDistance = 30

        this.reactiveObject = new ReactiveObject(1, 1, 1)
        this.cube = new Cube(1, 1, 1)
        this.circle = new Circle(4, 1, 16, 60)
    }

    start() {
        this.attachEvent()

        // this.camera.lookAt(this.scene.position)
        this.renderedObject = this.reactiveObject
        this.scene.add(this.renderedObject)
        this.camera.position.set(0, 0, 250)

        this.render()
    }

    changeObject(newObj) {
        this.scene.remove(this.renderedObject)

        switch (newObj) {
            case "default":
                this.renderedObject = this.reactiveObject
                this.camera.position.set(0, 0, 250)
                this.bloomPass.strength = 1
                this.bloomPass.bloomRadius = 0.5
                break
            case "cube":
                this.renderedObject = this.cube
                this.bloomPass.strength = 0.6
                this.bloomPass.bloomRadius = 0
                this.camera.position.set(0, 0, 25)
                break
            case "circle":
                this.renderedObject = this.circle
                this.bloomPass.strength = 1.5
                this.bloomPass.bloomRadius = 1
                this.camera.position.set(0, 0, 1300)
                break
            default:
                break
        }
        this.scene.add(this.renderedObject)
    }

    attachEvent() {
        window.addEventListener("resize", this.resize.bind(this))
    }

    resize() {
        this.w = window.innerWidth
        this.h = window.innerHeight
        this.camera.updateProjectionMatrix()
        this.camera.aspect = this.w / this.h
        this.renderer.setSize(this.w, this.h)
        this.renderer.setPixelRatio(window.devicePixelRatio)
    }

    render() {
        if (this.audiomanager.fdata != undefined) {
            this.audiomanager.update()
            this.renderedObject.render(this.audiomanager.fdata)
        }

        // this.controls.update()
        this.composer.render()
        requestAnimationFrame(this.render.bind(this))
    }
}
