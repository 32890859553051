import * as THREE from "three"

export default class Cube extends THREE.Group {
    constructor(w, h, depth) {
        super()

        this.color = 0x8f00ff

        for (let x = 0; x < 16; x++) {
            for (let y = 0; y < 16; y++) {
                const geometry = new THREE.BoxBufferGeometry(w, h, depth)
                geometry.applyMatrix4(
                    new THREE.Matrix4().makeTranslation(-7.5, -7.5, 0)
                )

                let material
                if (x % 2 == y % 2) {
                    material = new THREE.MeshBasicMaterial({
                        color: this.color,
                    })
                } else {
                    material = new THREE.MeshBasicMaterial({
                        color: 0xffffff,
                    })
                }
                const mesh = new THREE.Mesh(geometry, material)

                mesh.scale.z = 10
                mesh.position.set(y, x, 0)
                this.add(mesh)
            }
        }
    }

    render(frequence) {
        this.rotation.y += frequence[0] / 10000
        this.rotation.z += frequence[256] / 10000

        for (let i = 0; i < this.children.length; i++) {
            let off = frequence[i]
            this.children[i].scale.z = off / 35
        }
    }
}
