import * as THREE from "three"

export default class Cube extends THREE.Group {
    constructor(radius, tube, radialSegments, tubularSegments) {
        super()

        for (let i = 0; i < 256; i++) {
            const geometry = new THREE.TorusBufferGeometry(
                radius * i,
                tube,
                radialSegments,
                tubularSegments
            )
            const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })

            const mesh = new THREE.Mesh(geometry, material)

            mesh.position.z = i * 5

            this.add(mesh)
        }
    }

    render(frequence) {
        for (let i = 0; i < this.children.length; i++) {
            let off = frequence[i]
            // this.children[i].position.y = off / 2
            this.children[i].scale.x = frequence[i] / 500
            this.children[i].scale.y = frequence[i] / 500
            this.children[i].scale.z = frequence[i] / 500

            this.children[i].position.y += Math.sin(Date.now() * i)
        }
    }
}
