import fetchJsonp from "fetch-jsonp"
import { loadData, fadeOut, fadeIn, toggleLoader } from "./Utils"
import gsap from "gsap"

export default class AudioManager {
    constructor() {
        this.ctx = new (window.AudioContext || window.webkitAudioContext)()
        this.audio = document.getElementById("audio-player")
        this.controls = document.getElementById("controls")
        this.timeline = document.querySelector(".timeline")
        this.newSearch = document.querySelector(".new-search")
        this.error = document.getElementById("error")
        this.audio.volume = 0.1
        this.isPlaying = false
        this.playingSound = ""
        this.duration = null
        this.musics = []
        this.menuOpen = true

        this.audio.crossOrigin = "anonymous"
        this.audioSource = this.ctx.createMediaElementSource(this.audio)
        this.analyser = this.ctx.createAnalyser()
        // this.analyser.fftSize = 256
        this.analyser.smoothingTimeConstant = 0.8

        this.audioSource.connect(this.analyser)
        this.audioSource.connect(this.ctx.destination)

        this.fdata = new Uint8Array(this.analyser.frequencyBinCount)
        this.attachEvent()
        this.bind()
    }

    getSounds(artist) {
        toggleLoader(true)
        this.error.classList.remove("visible")
        this.error.innerHTML = ``

        fetchJsonp(`https://api.deezer.com/search?q='${artist}'&output=jsonp`)
            .then((res) => res.json())
            .then((res) => res.data)
            .then((musics) => {
                toggleLoader(false)

                if (musics.length > 10) {
                    this.musics = []
                    this.menuOpen = false
                    setTimeout(() => {
                        while (musics.length > 10) {
                            musics.pop()
                        }
                        musics.forEach((music) => {
                            this.musics.push(music.preview)
                        })

                        loadData(musics)
                        this.newSearch.classList.remove("hidden-search")
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.error.classList.add("visible")
                        this.menuOpen = true
                        this.error.innerHTML = `0 result found for ${artist}`
                        return
                    }, 1000)
                }
            })
            .catch(() => {
                this.menuOpen = true
                this.error.classList.add("visible")
                this.error.innerHTML = `Deezer API is not responding`
            })
    }

    playSelectedSound(dataAudio) {
        if (this.isPlaying && this.playingSound === this.musics[dataAudio]) {
            this.isPlaying = false
            this.audio.pause()
            this.controls.classList.remove("pause")
        } else if (
            this.isPlaying &&
            this.playingSound !== this.musics[dataAudio]
        ) {
            this.audio.pause()
            this.playingSound = this.musics[dataAudio]
            this.audio.setAttribute("src", this.playingSound)
            this.audio.play()
        } else {
            this.isPlaying = true
            this.playingSound = this.musics[dataAudio]
            this.audio.setAttribute("src", this.playingSound)
            this.audio.play()
            this.audio.volume = 0.1
        }
    }

    attachEvent() {
        this.audio.addEventListener("play", this.onAudioPlay.bind(this))
        this.audio.addEventListener("ended", this.onAudioEnd.bind(this))
        this.controls.addEventListener("click", this.onPlayerClicked.bind(this))
        this.newSearch.addEventListener("click", this.onOpenMenu.bind(this))
    }

    onOpenMenu() {
        if (this.menuOpen) {
            this.menuOpen = false
            fadeIn()
        } else {
            document.getElementById("user-input").focus()
            this.menuOpen = true
            fadeOut()
        }
    }

    onAudioPlay() {
        this.audio.onloadedmetadata = () => {
            this.duration = Math.round(this.audio.duration)

            document.querySelector(".duration").innerHTML =
                "00:" + this.duration
        }
        this.controls.classList.remove("disabled")
        this.controls.classList.add("pause")
    }

    onAudioEnd() {
        this.isPlaying = false
        this.controls.classList.remove("pause")
    }

    onPlayerClicked() {
        if (this.isPlaying) {
            this.isPlaying = false
            this.controls.classList.remove("pause")
            gsap.to(this.audio, {
                duration: 0.1,
                volume: 0,
                onComplete: () => this.audio.pause(),
            })
        } else {
            this.isPlaying = true
            this.audio.play()
            this.controls.classList.add("pause")
            gsap.to(this.audio, {
                duration: 0.1,
                volume: 0.1,
            })
        }
    }

    update() {
        this.analyser.getByteFrequencyData(this.fdata)

        if (this.duration !== null) {
            gsap.to(this.timeline, {
                duration: 0.5,
                width: Math.round(
                    (500 / this.duration) * Math.round(this.audio.currentTime) +
                        3
                ),
            })

            let currentTime = Math.round(this.audio.currentTime)

            if (currentTime < 10) {
                document.querySelector(".current-time").innerHTML =
                    "00:0" + currentTime
            } else {
                document.querySelector(".current-time").innerHTML =
                    "00:" + currentTime
            }
        }
    }

    bind() {
        this.update = this.update.bind(this)
    }
}
