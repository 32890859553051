import gsap from "gsap"

export const generateHTML = () => {
    const container = document.querySelector(".music-container")

    for (let i = 0; i < 10; i++) {
        const div = document.createElement("div")
        const title = document.createElement("span")
        const artist = document.createElement("span")
        const img = document.createElement("img")

        container.appendChild(div).setAttribute("class", "music-item")
        div.appendChild(img).setAttribute("data-audio", `data-audio-${i}`)
        div.appendChild(artist).setAttribute("class", "artist")
        div.appendChild(title).setAttribute("class", "title")
    }
}

export const loadData = (data) => {
    const collection = document.querySelectorAll(".music-item")
    const musics = Array.from(collection)

    musics.map((music, i) => {
        music.children[2].innerHTML = data[i].title_short
        music.children[1].innerHTML = data[i].artist.name
        music.children[0].src = data[i].album.cover_medium
    })

    fadeIn()
}

export const fadeIn = () => {
    const musics = document.querySelectorAll(".music-item")
    const userInput = document.getElementById("user-input")
    const audioContainer = document.querySelector(".search-audio-container")
    const controlsContainer = document.querySelector(".player")
    const views = document.querySelector(".select-view")

    gsap.to(userInput, {
        duration: 1,
        y: 200,
    })

    gsap.to(views, {
        duration: 1,
        opacity: 1,
    })

    gsap.to(audioContainer, {
        duration: 1,
        opacity: 0,
        onComplete: () => {
            audioContainer.classList.add("hide-audio-container")
            document.getElementById("user-input").value = ""
        },
    })

    gsap.to(musics, {
        duration: 1,
        opacity: 1,
        y: 250,
        stagger: 0.1,
    })

    gsap.to(controlsContainer, {
        duration: 1,
        y: -300,
        delay: 1,
    })
}

export const fadeOut = () => {
    const musics = document.querySelectorAll(".music-item")
    const userInput = document.getElementById("user-input")
    const audioContainer = document.querySelector(".search-audio-container")
    const controlsContainer = document.querySelector(".player")

    gsap.to(userInput, {
        duration: 1,
        y: 0,
    })

    audioContainer.classList.remove("hide-audio-container")
    gsap.to(audioContainer, {
        duration: 1,
        opacity: 1,
    })

    gsap.to(musics, {
        duration: 1,
        opacity: 0,
        y: 0,
        stagger: 0.1,
    })

    gsap.to(controlsContainer, {
        duration: 1,
        y: 0,
    })
}

export const detectSelectedSound = (e) => {
    if (
        e.target.localName === "img" &&
        e.target.parentElement.classList[0] === "music-item"
    ) {
        const dataAudio = parseInt(
            e.target.getAttribute("data-audio").substring(11)
        )

        return dataAudio
    }
}

export const toggleLoader = (bool) => {
    const loader = document.querySelector(".loader-container")
    if (bool) {
        gsap.to(loader, {
            duration: 1,
            opacity: 1,
        })
    } else {
        gsap.to(loader, {
            duration: 1,
            opacity: 0,
        })
    }
}
